import { useNavigate } from "@remix-run/react";
import { Check } from "lucide-react";
import { $path } from "remix-routes";
import { Button } from "~/components/ui/button";

const TopPage = () => {
	const navigate = useNavigate();

	return (
		<div className="bg-light-blue w-full">
			<div className="h-16 w-full flex flex-col justify-center pl-20">
				<img
					src="/assets/images/AI-KIJI-MOJI-KUN.png"
					alt="AI-KIJI-MOJI-KUN"
					className="w-56 px-1"
				/>
			</div>
			<div className="pt-8 pb-24">
				<div className="h-full flex">
					<div className="flex-1 border-r flex flex-col items-center justify-center">
						<div className="w-96 px-6">
							<img
								src="/assets/images/AI-KIJI-MOJI-KUN.png"
								alt="AI-KIJI-MOJI-KUN"
								className="mb-2"
							/>
							<p className="font-bold text-2xl text-dark-gray text-left mb-4">
								をはじめる。
							</p>
							<p className="mb-8">アカウントの登録が必要です。</p>
							<div className="flex gap-8 mb-20">
								<Button
									onClick={() => {
										navigate($path("/article/new"));
									}}
									variant="outline"
									className="w-40 border-dark-blue"
								>
									ログイン
								</Button>
								<Button
									onClick={() => {
										navigate($path("/account/signup"));
									}}
									className="w-40"
								>
									無料ではじめる
								</Button>
							</div>
							<img src="/assets/images/img_top_0.png" alt="img_top" />
						</div>
					</div>
					<div className="h-full flex-1 text-dark-gray flex flex-col justify-center items-center">
						<h2 className="mb-8 font-bold text-2xl">
							こんな悩みありませんか？
						</h2>
						<div className="mb-28 flex flex-col gap-4">
							<div className="flex items-center gap-6 text-sm font-bold">
								<img
									src="/assets/images/icon_peoplex3.png"
									alt="people"
									className="h-10"
								/>
								<p>記事制作に割くリソースがない...</p>
							</div>
							<div className="flex items-center gap-6 text-sm font-bold">
								<img
									src="/assets/images/icon_questionx3.png"
									alt="people"
									className="h-10"
								/>
								<p>記事作成に時間をかけたくない...</p>
							</div>
							<div className="flex items-center gap-6 text-sm font-bold">
								<img
									src="/assets/images/icon_timex3.png"
									alt="people"
									className="h-10"
								/>
								<p>記事の内容が思いつかない...</p>
							</div>
						</div>
						<h2 className="font-bold text-2xl mb-6">
							AI記事作成を手軽に、身近に。
						</h2>
						<div className="flex flex-col gap-6">
							<div className="flex gap-4">
								<Check className="text-dark-blue" />
								<div className="text-sm">
									<p className="mb-2 font-bold">記事に特化したAIツール</p>
									<p>
										キーワードを入力するだけで、記事を生成することが可能です。
									</p>
								</div>
							</div>
							<div className="flex gap-4">
								<Check className="text-dark-blue" />
								<div className="text-sm">
									<p className="mb-2 font-bold">無限大のバリエーション</p>
									<p>キーワードが同じでも毎度異なる記事を生成します</p>
								</div>
							</div>
							<div className="flex gap-4">
								<Check className="text-dark-blue" />
								<div className="text-sm">
									<p className="mb-2 font-bold">
										タイトル・目次は”選択”するだけでOK
									</p>
									<p>キーワードからAIがタイトルを自動生成してくれます</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TopPage;
